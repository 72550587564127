<template>
    <div class="d-flex flex-grow-1">
        <v-main class="align-center ma-auto w-full" style="max-width: 480px">
            <v-card :loading="isLoading">
                <template v-if="consentViewModel">
                    <v-form ref="form" v-model="isFormValid" lazy-validation @submit="submit">
                        <v-card-title>
                            <h2 v-html="$t('views.consent.alertHtml', { clientName: consentViewModel.clientName })" />
                        </v-card-title>
                        <v-card-text>
                            <v-input v-for="scope in consentViewModel.identityScopes" :key="scope.value" :messages="[ scope.description ]">
                                <v-checkbox
                                    slot="prepend"
                                    class="scope__scheckbox"
                                    v-model="model.scopesConsented"
                                    :value="scope.value"
                                    :indeterminate="scope.required"
                                    :disabled="scope.required"
                                />
                                <b v-if="scope.emphasize">{{ scope.displayName }}</b>
                                <span v-else>{{ scope.displayName }}</span>
                            </v-input>

                            <v-input v-for="scope in consentViewModel.apiScopes" :key="scope.value" :messages="[ scope.description ]">
                                <v-checkbox
                                    slot="prepend"
                                    class="scope__scheckbox"
                                    v-model="model.scopesConsented"
                                    :value="scope.value"
                                    :indeterminate="scope.required"
                                    :disabled="scope.required"
                                />
                                <b v-if="scope.emphasize">{{ scope.displayName }}</b>
                                <span v-else>{{ scope.displayName }}</span>
                            </v-input>

                            <v-container class="mt-2">
                                <v-row align="center" justify="center" dense>
                                    <v-col>
                                        <v-btn type="submit" color="primary" name="button" value="yes" x-large block :loading="isSubmitting" :disabled="isSubmitting">
                                            {{ $t('views.consent.yes') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-btn type="submit" color="default" name="button" value="no" x-large block :loading="isSubmitting" :disabled="isSubmitting">
                                            {{ $t('views.consent.no') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row align="center" justify="center">
                                    <a class="ma-1" href="#" @click="changeAccount">
                                        {{ $t('views.consent.changeAccount') }}
                                    </a>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-form>
                </template>
                <template v-else>
                    <v-card-text class="text-center">
                        {{ $t('views.consent.invalidConsentRequest') }}
                    </v-card-text>
                </template>
            </v-card>
        </v-main>
    </div>
</template>

<style scoped>
    .v-input--selection-controls {
        margin-top: 0;
    }
</style>

<script>
import _ from 'lodash';
import utils from '@/utils';
import { getConsentViewModel, processConsent } from '@/api/consent';

export default {
    data () {
        return {
            isLoading: false,
            isSubmitting: false,
            isFormValid: false,
            consentViewModel: null,
            model: {
                scopesConsented: [],
                rememberConsent: true,
                returnUrl: null
            }
        };
    },
    // computed: {
    //     alertHtml () {
    //         var format = this.$t('views.consent.alertHtml');
    //         return utils.string.format(format, { clientName: this.consentViewModel.clientName });
    //     }
    // },
    methods: {
        async submit (e) {
            e.preventDefault();
            if (!this.$refs.form.validate()) return;

            this.isSubmitting = true;
            var postData = _.defaults(
                {
                    button: e.submitter.value
                },
                this.model);

            try {
                var { redirectUri } = await processConsent(postData);
                // 跳转到指定地址, 因为redirect地址不一定是本站，所以需要用window.location.href
                // 而不能使用 this.$router.push(redirect); 因为$router.push不会访问到服务器
                window.location.href = redirectUri;
            } catch (ex) {
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        },
        changeAccount (e) {
            e.preventDefault();

            var returnUrl = utils.route.getQueryString(this.$route, 'returnUrl');
            var path = utils.route.generateUrl(
                '/passport/login',
                {
                    returnUrl
                });
            this.$router.push(path);
        }
    },
    async created () {
        this.isLoading = true;
        var returnUrl = utils.route.getQueryString(this.$route, 'returnUrl');
        this.consentViewModel = await getConsentViewModel({ returnUrl });

        if (this.consentViewModel) {
            this.model.scopesConsented = _(_.union(this.consentViewModel.identityScopes, this.consentViewModel.apiScopes))
                .map(item => item.value)
                .value();
            this.model.returnUrl = returnUrl;
        }

        this.isLoading = false;
    }
};
</script>
